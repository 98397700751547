import $ from 'jquery';
import 'what-input';


// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');
import 'slick-carousel';

$(document).foundation();

// Prevent small screen page refresh sticky bug
$(window).on('sticky.zf.unstuckfrom:bottom', function(e) {
    if (!Foundation.MediaQuery.atLeast('medium')) {
      $(e.target).removeClass('is-anchored is-at-bottom').attr('style', '');
    }
  });


const theProductsSlider = document.querySelector('.productsSlideshow');
if( theProductsSlider !== null ) {

  $('.productsSlideshow').slick({
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    fade: true,
    cssEase: 'linear'
  });

  // get all images from main slider
  const sliderItems = document.querySelector('.productsSlideshow').slick.$slides;
  const sliderItemsSrc = [];
  for (let item = 0; item<sliderItems.length; item++) {
    sliderItemsSrc.push(sliderItems[item].children[0].children[0].children[0].src);
  }

  // construct slideshow thumbnails
  const sliderItemsCount = document.querySelector('.productsSlideshow').slick.slideCount;
  const sliderThumbnailsContainer = document.querySelector('.productsSlideshowThumbnails');
  const sliderThumbnailsTemplate = document.querySelector('#productsSlideshowThumbnailsTemplate');
  for (let sliderItem = 0; sliderItem < sliderItemsCount; sliderItem++) {
    const clone = sliderThumbnailsTemplate.content.cloneNode(true);
    const sliderThumbnailsInput = clone.querySelector('input');
    sliderThumbnailsInput.setAttribute("id", "slick-slide-control"+sliderItem+1);
    sliderThumbnailsInput.setAttribute("aria-controls", "slick-slide"+sliderItem+1);
    sliderThumbnailsInput.setAttribute("aria-label", sliderItem+1+" of "+sliderItemsCount);
    sliderThumbnailsInput.setAttribute("data-slide-target", sliderItem);
    sliderThumbnailsInput.setAttribute("src", sliderItemsSrc[sliderItem]);
    sliderThumbnailsInput.textContent = sliderItem+1;
    sliderThumbnailsContainer.appendChild(clone);
  }
  const sliderInputs = sliderThumbnailsContainer.querySelectorAll('input');
  function changeSlide(){
    const slideToShow = this.dataset.slideTarget;
    document.querySelector('.productsSlideshow').slick.slickGoTo(slideToShow);
  }
  sliderInputs.forEach(element => element.addEventListener("click", changeSlide));
}


const consentTrigger = document.getElementById('cookie__consent--info-trigger');
if(consentTrigger !== null) {
  consentTrigger.addEventListener('click', function(){
    CookieConsent.show();
  });
}
